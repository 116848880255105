import React from "react";
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"

import Seo from "../components/seo"

import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import {faClock, faFolderOpen} from "@fortawesome/free-regular-svg-icons";

const postTemplate = ({ data, location, pageContext }) => (

    <Layout>

        <Seo
            pagetitle="ニュース・ブログ一覧"
            pagedesc="ZEROSTEP for バスケットボールのニュース・ブログ一覧です。"
            pagepath={location.pathname}
        />

        <section className="content bloglist">
            <div className="container">
                <h1 className="bar">ニュース・ブログ一覧</h1>

                <div className="posts ">

                    {data.allContentfulBlogPost.edges.map(({ node }) => (
                        <article className="post post-item post-1_2" key={node.id}>
                            <Link to={`/post/${node.slug}`}>
                                <figure>
                                    <GatsbyImage
                                        image={node.eyecatch.gatsbyImageData}
                                        alt={node.eyecatch.description}
                                        style={{height:"100%"}} />
                                </figure>
                                <aside className="info post-item_info">
                                    <time dateTime={ node.publishDate }>
                                        <FontAwesomeIcon icon={faClock} />
                                        { node.publishDateJP }
                                    </time>

                                    <div className="cat">
                                        <FontAwesomeIcon icon={faFolderOpen} />
                                        <ul>
                                            {node.category.map(cat => (
                                                <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </aside>
                                <h3>{ node.title }</h3>
                            </Link>
                        </article>
                    ))}
                </div>

                <ul className="pagenation">
                    {!pageContext.isFirst && (
                        <li className="prev">
                            <Link
                                to={
                                    pageContext.currentPage === 2
                                        ? `/post/`
                                        : `/post/${pageContext.currentPage - 1}/`
                                }
                                rel="prev"
                            >
                                <FontAwesomeIcon icon={faChevronLeft} />
                                <span>前のページ</span>
                            </Link>
                        </li>
                    )}
                    {!pageContext.isLast && (
                    <li className="next">
                        <Link
                            to={`/post/${pageContext.currentPage + 1}/`}
                            rel="next"
                        >
                            <span>次のページ</span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Link>
                    </li>
                    )}
                </ul>
            </div>
        </section>

    </Layout>

)
export default postTemplate;

export const query = graphql`
    query($skip: Int!, $limit: Int!){
        allContentfulBlogPost(
            sort: {order: DESC, fields: publishDate}
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    title
                    publishDateJP : publishDate(formatString: "YYYY年MM月DD日")
                    publishDate   
                    category {
                      category
                      categorySlug
                      id
                    }
                    id
                    slug
                    eyecatch{
                      description
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED
                        width: 500
                        layout: FULL_WIDTH
                      )
                      file {
                        details {
                          image {
                            height
                            width
                          }
                        }
                        url
                      }
                    }
                }
            }
        }                    
    }
`